$(document).foundation();

$(document).ready(function()
{
    // cookie box
    $( '.cookie-button' ).click( function()
    {
        Cookies.set( 'cookie_notice', '1', { expires: 365, path: '/' } );
        $( '.box-cookie' ).slideUp();

        return false;
    });


    if( Cookies.get( 'cookie_notice' ) )
    {
        $( '.box-cookie' ).hide();
    }
    else
    {
        $( '.box-cookie' ).show();
    }

    // age box
    $( '.age-enter' ).click( function()
    {
        Cookies.set( 'age', '1', { expires: 1, path: '/' } );
        $('#age').foundation('close');

        return false;
    });


    if( !Cookies.get( 'age' ) )
    {
        $('#age').foundation('open');
    }


    $('header').headroom({
        "offset": 150,
        "tolerance": 0
    });


    $('.main-items').owlCarousel({
        animateOut: 'slideOutLeft',
        animateIn: 'slideInRight',
        items:1,
        loop:true,
        dots:false,
        autoplay:true,
        autoplayTimeout:8200,
        autoplayHoverPause:true,
        responsive:{
            0:{
                nav:false
            },
            641:{
                nav:false
            },
            1025:{
                nav:false
            }
        }
    });

    var links = [];

    $(".box-section article figure img, .box-section-front-page article figure img").each(function() {
        var $this = $(this);
        var src = $this.attr('src');
        links.push(src);
        $this.attr('gallery-index', links.length - 1);
    });

    $(".box-section article figure img, .box-section-front-page article figure img").click(function(event) {
        event = event || window.event;
        var target = event.target || event.srcElement,
            link = target.src ? target.parentNode : target,
            options = {index: link, event: event};
        var gallery = blueimp.Gallery(links, options);
        var gallery_index = parseInt( $(this).attr('gallery-index') );

        if( ( gallery_index + 1 ) == links.length )
        {
            gallery.slide( ( gallery_index - 1 ), 100 );
            gallery.next();
        }
        else
        {
            gallery.slide( gallery_index, 100 );
        }
    });
});